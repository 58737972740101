import React, { FunctionComponent } from "react"
import { PodcastShow } from "../../podcast-types"
import styles from "./podcast-show.module.scss"
import { PodcastListenLinks } from "@features/podcasts/components/podcast-show-links/podcast-listen-links"

export interface PodcastShowWaysToListenProps {
  show: PodcastShow
}

export const PodcastShowWaysToListen: FunctionComponent<
  PodcastShowWaysToListenProps
> = ({ show }) => {
  const imageBackground = show.color
    ? { backgroundColor: show.color }
    : undefined

  const listenKeys = Object.keys(show.listenLinks)

  return (
    <header className={styles.header}>
      <div className={styles.showArtwork} style={imageBackground}>
        {show.image && (
          <div className={styles.showImageWrapper}>
            <img
              className={styles.showImage}
              src={show.image}
              alt={show.title}
            />
          </div>
        )}
      </div>
      {listenKeys.length && (
        <aside className={styles.latestEpisode}>
          <PodcastListenLinks
            showTitle={show.title}
            showOverline={show.topic}
            showDescription={show.description || ""}
            showHosts={show.hosts}
            {...show.listenLinks}
          />
        </aside>
      )}
    </header>
  )
}
