import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { PodcastShowListenLinks, PodcastTeacher } from "../../podcast-types"
import {
  primaryListenLinks,
  secondaryListenLinks
} from "./podcast-show-link-constants"
import styles from "./podcast-show-links.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { ShareAndLikeButton } from "@components/share-and-like-button/share-and-like-button"
import { Teacher } from "@src-types/teacher"
import { AvatarList } from "@components/avatar/avatar-list"
import { Link } from "@components/link/link"
import { getEpisodeTeachers } from "@features/podcasts/mappers/podcast-show-mappers"

export interface PodcastListenLink {
  type: "primary" | "secondary"
  image: string
  title: string
  href?: string
}

export interface PodcastListenLinksProps extends PodcastShowListenLinks {
  showTitle?: string
  showDescription?: string
  showOverline?: string
  showHosts?: PodcastTeacher[]
}

export const PodcastListenLinks: FunctionComponent<PodcastListenLinksProps> = (
  props
) => {
  const links: PodcastListenLink[][] = [
    primaryListenLinks,
    secondaryListenLinks
  ].map((links, index) =>
    links.map((link) => ({
      href: props[link.key] || "",
      type: index ? "secondary" : "primary",
      ...link
    }))
  )

  const { showTitle, showDescription, showOverline, showHosts } = props

  const { name: showHostsName, link: showHostsLink } = getEpisodeTeachers({
    teachers: showHosts
  })

  return (
    <div
      data-testid={"podcastListenLinks"}
      className={classNames(styles.linkContainer, styles.listenLinks)}
    >
      {showTitle ? (
        <>
          {showOverline && (
            <div className={styles.overline}>{showOverline}</div>
          )}
          <h1 className={styles.showTitle}>{showTitle}</h1>
          {showDescription && (
            <Markdown
              className={styles.showDescription}
              content={showDescription}
            />
          )}
          <div className={styles.showMeta}>
            <Link className={styles.showHosts} to={showHostsLink}>
              {showHosts && (
                <AvatarList avatars={showHosts as Teacher[]} size="sm" />
              )}
              <span className={styles.showHostsName}>{showHostsName}</span>
            </Link>
            <ShareAndLikeButton className={styles.shareButton} />
          </div>
        </>
      ) : (
        <h3 className={styles.linkTitle}>
          <FormattedMessage id="podcasts-listen-links-title" />
        </h3>
      )}
      {links.map((links, i) => (
        <ul className={styles.linkList} key={i}>
          {links.map((link, j) =>
            link.href ? (
              <li className={styles.linkItem} key={j}>
                <a
                  className={styles.listenLink}
                  href={link.href}
                  title={link.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classNames(
                      styles.listenImage,
                      styles[link.type]
                    )}
                    src={`/assets/podcasts/${link.image}`}
                    alt={link.title}
                  />
                </a>
              </li>
            ) : null
          )}
        </ul>
      ))}
    </div>
  )
}
