import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Page } from "../../../components/page/page"
import { PageProps } from "../../../types/page"
import { mapPodcastShowQueryToView } from "../mappers/podcast-show-mappers"
import { PodcastShowWaysToListen } from "../views/podcast-show/podcast-show-listing-only"
import { PodcastShowQuery } from "../podcast-types"

export const PodcastShowWaysToListenTemplate: FunctionComponent<
  PageProps<PodcastShowQuery>
> = ({ data, ...pageProps }) => {
  const { metadata, show } = mapPodcastShowQueryToView(
    data!,
    pageProps.pageContext
  )

  return (
    <Page fullHeight={false} {...pageProps} metadata={metadata}>
      <PodcastShowWaysToListen show={show} />
    </Page>
  )
}

export default PodcastShowWaysToListenTemplate

export const query = graphql`
  query PodcastShowWaysToListen($showId: String) {
    contentfulPodcastShow(id: { eq: $showId }) {
      ...PodcastShowView
    }
  }
`
