import { PodcastListenLink, PodcastSocialLink } from "./podcast-show-link-types"

export const primaryListenLinks: PodcastListenLink[] = [
  {
    title: "Apple",
    image: "apple-badge.svg",
    key: "applePodcastsUrl"
  },
  {
    title: "Spotify",
    image: "spotify-badge.svg",
    key: "spotifyUrl"
  },
  {
    title: "Google",
    image: "google-badge.svg",
    key: "googlePodcastsUrl"
  }
]

export const secondaryListenLinks: PodcastListenLink[] = [
  {
    title: "iHeartRadio",
    image: "iheart-badge.svg",
    key: "iHeartRadioUrl"
  },
  {
    title: "Sticher",
    image: "stitcher-badge.svg",
    key: "stitcherUrl"
  },
  {
    title: "Pandora",
    image: "pandora-badge.png",
    key: "pandoraUrl"
  },
  {
    title: "Deezer",
    image: "deezer-badge.png",
    key: "deezerUrl"
  },
  {
    title: "Tune In",
    image: "tunein-badge.svg",
    key: "tuneInUrl"
  },
  {
    title: "RSS",
    image: "rss-badge.svg",
    key: "rssUrl"
  }
]

export const socialLinks: PodcastSocialLink[] = [
  {
    title: "Facebook",
    icon: "20-facebook",
    key: "facebookUrl"
  },
  {
    title: "Twitter",
    icon: "20-twitter",
    key: "twitterUrl"
  },
  {
    title: "Instagram",
    icon: "20-instagram",
    key: "instagramUrl"
  }
]
